@import '../../assets/scss/app-variables';

.passwordContainer {
  display: flex;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;

    .passwordForm {
      input {
        margin-bottom: $space-between-x2;
      }
    }
  
    .passwordError {
      color: $error-color;
      margin-bottom: $space-between;
    }

    .btnContainer {
      display: flex;
      justify-content: center;
    }
  }
}

@include screen('large') {
  .passwordContainer {
    align-items: center;
  }
}