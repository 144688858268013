@import '../../assets/scss/app-variables';

.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 13px);

  .spinContent {
    img {
      width: 192px;
      height: 165px;
    }
  }
}