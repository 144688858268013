@import '../../assets/scss/app-variables';

.seeYouContainer {
  background-color: #ffecec;
  display: flex;
  justify-content: center;
  height: calc(100% - 20px);
  padding: 20px 20px 0;

  .slideBodySeeYou {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    .title-logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: $space-between-x2;

      h2 {
        margin: 0;
      }

      img {
        margin-bottom: $space-between;
        width: 235px;
        height: 47px;
      }
    }

    p {
      text-align: center;
    }

    .motorbikeContainer {
      img {
        width: 558px;
        height: 558px;
      }
    }
  }
}

@include screen('small') {
  .seeYouContainer {
    .slideBodySeeYou {
      .motorbikeContainer img {
        width: 289px;
        height: 300px;
      }
    }
  }
}