@import './app-variables';

body {
  color: $primary-blue;
  font-family: $main-font-family;
  font-size: 1rem; // 16px
  font-style: normal;
  font-weight: 400;
  height: 100vh;
  line-height: 1rem;
  margin: 0;
  overflow-x: hidden;

  #root {
    height: 100%;

    .slideContainer {
      background-color: $white;
      height: calc(100% - 20px);
      padding: 20px 20px 0;

      &.blue-bg {
        background-color: $intro-background-color;
      }

      &.halfBg {
        background-color: transparent;
        position: relative;
        z-index: 2;
      }
    }
  }
}

h1, .h1 {
  font-size: 2.25rem; // 36px
  font-weight: 600;
  line-height: 2.125rem; // 34px
  margin: 0;
}

h2, .h2,
h3, .h3 {
  font-size: 1.75rem; // 28px
  font-weight: 600;
  line-height: 1.875rem; // 30px
  margin: 0 0 $space-between-x2;
}

h4, .h4 {
  font-size: 1.313rem; // 21px
  font-weight: 600;
  line-height: 1.563rem; // 25px
  margin: 0;
}

p {
  font-size: 0.938rem; // 15px
  font-weight: 600;
  line-height: 1.25rem; // 20px
  margin: 0;

  &.small {
    font-size: 0.75rem; // 12px
    font-weight: 500;
    line-height: 1rem; // 16px   
  }

  &.smallB {
    font-size: 0.75rem; // 12px
    font-weight: 600;
    line-height: 1rem; // 16px   
  }

  &.tiny {
    font-size: 0.625rem; // 10px
    font-weight: 500;
    line-height: 0.875rem; // 14px    
  }
}

a, .link {
  cursor: pointer;
  font-size: 0.75rem; // 12px
  font-weight: 600;
  line-height: 0.938rem; // 15px
  margin: $space-between 0 0;
  text-align: center;
  text-decoration: underline;
}

input:focus {
  outline: none;
}

input[type='text'], input[type='password'],
select {
  background: white;
  border-bottom: solid 1px $shadow;
  border-left: none;
  border-right: none;
  border-top: none;
  color: $primary-blue;
  display: inline-block;
  font-family: $main-font-family;
  font-size: 0.875rem; // 14px
  font-weight: 500;
  line-height: 1.188rem; // 19px
  margin-top: 10px;
  max-width: 30rem;
  width: 100%;
  padding: 0 0 5px;
}

textarea {
  background: white;
  border: 1px solid #DFDFEC;
  border-radius: 8px;
  box-sizing: border-box;
  color: $primary-blue;
  display: inline-block;
  font-family: $main-font-family;
  font-size: 0.875rem; // 14px
  font-weight: 500;
  line-height: 1.188rem; // 19px
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 1rem;

  &:focus {
    border: 2px solid $intro-background-color;
  }

  &:focus-visible {
    outline: none;
  }
}

input[type="text"]::placeholder,
input[type="password"]::placeholder,
textarea::placeholder {
  color: $primary-blue-50;
}

@include screen('large') {
  h1, .h1 {
    font-size: 3rem; // 48px
    line-height: 3rem; // 48px
  }

  h2, .h2,
  h3, .h3,
  h4, .h4 {
    &.web {
      font-size: 3rem; // 48px
      line-height: 3rem; // 48px
    }
  }
}

@include screen('small') {
  body #root {
    .slideContainer.blue-bg {
      height: calc(100% + 70px);
    }
  }
}